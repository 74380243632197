import React from "react"
import Helmet from "react-helmet"
import { graphql } from 'gatsby'
import Layout from "../components/layout"

const ContactPage = ({
  data: {
    site
  },
}) => {
  return (
    <Layout>
      <Helmet>
        <title>Contact — {site.siteMetadata.title}</title>
        <meta name="description" content={"Kontaktseite von " + site.siteMetadata.description} />
      </Helmet>
      <div className="two-grids -contact">
        <div className="post-thumbnail" style={{backgroundImage: `url('/assets/alexander-andrews-HgUDpaGPTEA-unsplash.jpg')`, marginBottom: 0}}>
          <h1 className="post-title">Kontaktiere uns</h1>
          {/*<p>Let me help you kick start your next project &rarr;</p>*/}
        </div>
        <div>
          <form className="form-container" name="Kontaktformular" method="POST" data-netlify="true"   netlify-honeypot="bot-field">
            <input type="hidden" name="form-name" value="Kontaktformular" />
            <div className="hidden">
              <label>
                Don’t fill this out if you’re human: <input name="bot-field"/>
              </label>
            </div>
            <div>
              <label htmlFor="name">Name</label>
              <input type="text" name="Name" id="name"/>
            </div>
            <div>
              <label htmlFor="email">Email</label>
              <input type="email" name="Email" id="email"/>
            </div>
            <div>
              <label htmlFor="title">Titel</label>
              <input type="text" name="Titel" id="title"/>
            </div>
            <div>
              <label htmlFor="message">Nachricht</label>
              <textarea name="Nachricht" id="message"></textarea>
            </div>
            <div style={{display: "flex", justifyContent: "flex-end"}}>
              <input type="submit" className="button -primary" style={{marginRight: 0}} />
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}
export default ContactPage
export const pageQuery = graphql`
  query ContactPageQuery{
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`